import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import ConfirmButton from '../ConfirmButton/ConfirmButton';

import "./DailyReportPreview.css"

const DailyReportPreviewEdit = (props) => {

    const [entry, setEntry] = useState(props.entry);
    const textareas = useRef({});

    const [jobArray, setJobArray] = useState([]);

    useEffect(() => {
        let tmpArray = [];
        [1, 2, 3, 4].forEach(col => {
            Object.keys(entry).forEach(key => {
                if (tmpArray.findIndex(item => item.col === col) === -1 && key.indexOf(`job${col}`) === 0 && entry[key]) tmpArray.push({ col: col, rows: [] });
            })
        })
        tmpArray.forEach(job => {
            const rows = [...new Set(
                Object.keys(entry)
                    // .filter(key => key.substring(0, 4) === `job${job.col}` && entry[key])
                    .map(key => {
                        if (key.indexOf('job' + job.col) === -1) return 0;
                        const match = key.match(/(\d+)$/);  // Regex to find digits at the end of the string
                        return match ? parseInt(match[0], 10) : 0;  // Convert matched string to integer
                    })
                    .filter(char => !isNaN(parseInt(char)))
                    .map(char => parseInt(char))
            )];
            rows.shift();
            job.rows = rows.sort((a, b) => a - b); // [...Array(Math.max(...rows))].map((_, index) => index + 1);
        })
        setJobArray(tmpArray);
    }, [entry])

    const onChange = (e) => {
        let tempObj = { ...entry };
        tempObj[e.target.name] = e.target.value;
        setEntry(tempObj);
    }

    const resizeTextarea = (textarea) => {
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    useEffect(() => {
        // Resize all textareas when the component mounts or `entry` changes
        Object.values(textareas.current).forEach(resizeTextarea);
    }, [entry]);

    const registerTextarea = (name) => (el) => {
        if (el) {
            textareas.current[name] = el;
            resizeTextarea(el);
        }
    };


    return (
        <div id="">
            <div id="dailyReportPreviewContainer" className="page-container">
                <Container fluid>
                    <Row className='my-2 mx-0 text-end'>
                        <Col className="text-start" md={2}>
                            <img src="/logo-asta-construction.png" style={{height: '60px'}} />
                        </Col>
                        <Col className="text-start">
                            <h1 style={{fontFamily: 'sans-serif'}}>Daily Field Report #{props.entryId}</h1>
                        </Col>
                        <Col className="p-0">
                            {JSON.stringify(props.entry) === JSON.stringify(entry) ?
                                <>
                                    <Button
                                        onClick={() => props.onUpdate(props.entryId, entry)}
                                        color="success"
                                        disabled={true}
                                        className='me-2'
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        onClick={props.hidePreview}
                                        color="secondary"
                                    >
                                        Close
                                    </Button>
                                </>
                                :
                                <>
                                    <Button
                                        onClick={() => props.onUpdate(props.entryId, entry)}
                                        color="success"
                                        className='me-2'
                                    >
                                        Save
                                    </Button>
                                    <ConfirmButton
                                        onClick={props.hidePreview}
                                        color="secondary"
                                        message="You have unsaved changes. Do you wish to continue?"
                                    >
                                        Close
                                    </ConfirmButton>
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
                {jobArray.length === 0 && <h1>No Jobs</h1>}
                {jobArray.map(item => item.col).map(col => (
                    <table key={col}>
                        <tbody>
                            <tr>
                                <td colSpan="2"><label>Date</label>
                                    <input name="date" type="date" value={entry.date || ''} onChange={onChange} />
                                </td>
                                <td><label>From</label>
                                    <input name="shiftFrom" type="time" value={entry.shiftFrom || ''} onChange={onChange} />
                                </td>
                                <td><label>To</label>
                                    <input name="shiftTo" type="time" value={entry.shiftTo || ''} onChange={onChange} />
                                </td>
                                <td colSpan="5"><label>Job</label>
                                    <input name={`job${col}_job`} type="text" value={entry[`job${col}_job`] || ''} onChange={onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4"><label>Foreman</label>
                                    <input disabled id="user" name="user" type="text" value={props.user} />
                                </td>
                                <td colSpan="5"><label>RFCO</label>
                                    <input name={`job${col}_rfco`} type="text" value={entry[`job${col}_rfco`] || ''} onChange={onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4"><label>Email</label>
                                    <input disabled id="email" name="email" type="text" value={props.email} />
                                </td>
                                <td colSpan="3"><label>Job No.</label>
                                    <input name={`job${col}_jobNumber`} type="text" value={entry[`job${col}_jobNumber`] || ''} onChange={onChange} />
                                </td>
                                <td colSpan="2"><label>Code No.</label>
                                    <input name={`job${col}_codeNumber`} type="text" value={entry[`job${col}_codeNumber`] || ''} onChange={onChange} />
                                </td>
                            </tr>
                            <tr>
                                <th width="20%" colSpan={2}>Employee Name</th>
                                {/* <th width="10%">Emp No.</th> */}
                                <th width="8%">Class</th>
                                <th width="8%">Rate</th>
                                <th width="8%">ST Hrs</th>
                                <th width="8%">OT Hrs</th>
                                <th width="8%">DBL Hrs</th>
                                <th width="20%">Equip No</th>
                                <th width="10%">Equip Hrs</th>
                            </tr>
                            {jobArray.find(item => item.col === col).rows.map(row => (
                                <tr key={row}>
                                    <td colSpan={2}>
                                        <input name={`employeeName${row}`} type="text" value={entry[`employeeName${row}`] || ''} onChange={onChange} />
                                        {/* <select name={`employeeName${row}`}>
                                    <option>{props[`employeeName${row}`] || ''}</option>
                                </select> */}
                                    </td>
                                    {/* <td>
                                    <input name={`employeeNumber${row}`} type="text" value={entry[`employeeNumber${row}`] || ''} onChange={onChange} />
                                </td> */}
                                    <td className="lightBlueGrey">
                                        <input name={`class${row}`} type="text" value={entry[`class${row}`] || ''} onChange={onChange} />
                                    </td>
                                    <td className="lightBlueGrey">
                                        <input name={`rate${row}`} min="0" step="0.5" type="number" value={entry[`rate${row}`] || ''} onChange={onChange} />
                                    </td>
                                    <td>
                                        <input name={`job${col}_standardHours${row}`} min="0" step="0.5" type="number" value={entry[`job${col}_standardHours${row}`] || ''} onChange={onChange} />
                                    </td>
                                    <td>
                                        <input name={`job${col}_overtimeHours${row}`} min="0" step="0.5" type="number" value={entry[`job${col}_overtimeHours${row}`] || ''} onChange={onChange} />
                                    </td>
                                    <td>
                                        <input name={`job${col}_doubleHours${row}`} min="0" step="0.5" type="number" value={entry[`job${col}_doubleHours${row}`] || ''} onChange={onChange} />
                                    </td>
                                    <td className="lightBlueGrey">
                                        <input name={`job${col}_equipmentNumber${row}`} type="text" value={entry[`job${col}_equipmentNumber${row}`] || ''} onChange={onChange} />
                                        {/* <select name={`job${col}_equipmentNumber${row}`}>
                                    <option>{props[`job${col}_equipmentNumber${row}`] || ''}</option>
                                </select> */}
                                    </td>
                                    <td className="lightBlueGrey">
                                        <input name={`job${col}_equipmentHours${row}`} min="0" step="0.5" type="number" value={entry[`job${col}_equipmentHours${row}`] || ''} onChange={onChange} />
                                    </td>
                                </tr>))}
                            {entry[`job${col}_equipmentRentals`] && <tr>
                                <td colSpan="4" className="stripedBG" >
                                    &nbsp;
                                </td>
                                <td colSpan="5"><label>Equipment Rentals</label>
                                    <textarea name={`job${col}_equipmentRentals`} rows="2" onChange={onChange} >{entry[`job${col}_equipmentRentals`]}</textarea>
                                </td>
                            </tr>}
                            {entry[`job${col}_materialDeliveredToJobType`] && <tr>
                                <td colSpan="4" className="stripedBG" >
                                    &nbsp;
                                </td>
                                <td colSpan="5"><label>Material Delivered to Job - Type</label>
                                    <textarea name={`job${col}_materialDeliveredToJobType`} rows="2" onChange={onChange}>{entry[`job${col}_materialDeliveredToJobType`]}</textarea>
                                </td>
                            </tr>}
                            {entry[`job${col}_materialDeliveredToJobQuantity`] && <tr>
                                <td colSpan="4" className="stripedBG" >
                                    &nbsp;
                                </td>
                                <td colSpan="5"><label>Material Delivered to Job - Quantity</label>
                                    <textarea name={`job${col}_materialDeliveredToJobQuantity`} rows="2" onChange={onChange}>{entry[`job${col}_materialDeliveredToJobQuantity`]}</textarea>
                                </td>
                            </tr>}
                            {entry[`job${col}_materialDeliveredToJobFrom`] && <tr>
                                <td colSpan="4" className="stripedBG" >
                                    &nbsp;
                                </td>
                                <td colSpan="5"><label>Material Delivered to Job - From</label>
                                    <textarea name={`job${col}_materialDeliveredToJobFrom`} rows="2" onChange={onChange}>{entry[`job${col}_materialDeliveredToJobFrom`]}</textarea>
                                </td>
                            </tr>}
                            {entry[`job${col}_materialDeliveredToJobHauledBy`] && <tr>
                                <td colSpan="4" className="stripedBG" >
                                    &nbsp;
                                </td>
                                <td colSpan="5"><label>Material Delivered to Job - Hauled By</label>
                                    <textarea name={`job${col}_materialDeliveredToJobQHauledBy`} rows="2" onChange={onChange}>{entry[`job${col}_materialDeliveredToJobHauledBy`]}</textarea>
                                </td>
                            </tr>}
                            <tr>
                                <td colSpan="4"><label>Foreman Signature</label>
                                    {entry.foremanSignature && <img id="foremanSignature" src={entry.foremanSignature} alt="Foreman Signature" />}
                                </td>
                                <td colSpan="5"><label>Comments</label>
                                    <textarea name="comments" rows="1" onChange={onChange} ref={registerTextarea('comments')} value={entry.comments || ''}></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ))}
                <hr />
            </div>

        </div>

    )
}

export default DailyReportPreviewEdit;